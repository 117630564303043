export const environment = {
  production: false,
  envName: 'test',
  appClientId: "portal",
  apiUrl: 'https://testportal.sinric.pro',
  sseUrl: 'https://testportal.sinric.pro/sse',
  manufacturerId: "5c67c495268198273c8551cd",
  manufacturerName: "Sinric Pro",
  googleCaptcha: "6Lfm5qkUAAAAADI80yBrLJxY3tgeoOoJSZuCFbRF",
  googleAnalyticsEnabled : false,
  googleAnalyticsTrackingCode: "UA-148908787-1",
  version: "v2.35.5", 
  
  // Header
  header:  {
    socialMediaLinksVisible: true
  },

  register : {
    showFullScreenSignup: false
  },

  dashboard:  {
    showInSideMenu: true
  },

  devices:  {
    showInSideMenu: true,
    showAddDeviceButton: true,
    showRetailItems: true    
  },

  rooms:  {
    showInSideMenu: true,
  },

  scense:  {
    showInSideMenu: true,
  },

  schedules:  {
    showInSideMenu: true,
  },

  credentials:  {
    showInSideMenu: true,
    showAddAPIKeyButton: true,
    showNewAppKeyAndCredentials: true
  },

  activityLog:  {
    showInSideMenu: true,
  },

  account:  {
    showInSideMenu: true,
  },

  subscriptions:  {
    showInSideMenu: true,
  },
    
  whatsNew:  {
    showInSideMenu: true,
  },

  apiIntegration:  {
    showInSideMenu: true,
  },

  showDeviceTemplate: {
    showInSideMenu: true,
  },

  emailConfirmation: {
    redirectToHomeAfterEmailConfirmationSuccess: true,
  },

  forgotPassword: {
    redirectUrlAfterResetSuccess: "/",
    isExternalUrlRedirect: false
  },

  resetPassword: {
    redirectUrlAfterResetSuccess: "/",
    isExternalUrlRedirect: false
  },

  energyEstimates: {
    showInSideMenu: true,
  },

  zeroCode: {
    showInSideMenu: true,
  },
}; 

// ng build --configuration=test

/*
Paypal test login
Email ID:
sb-nfefn384118@personal.example.com

Password: 
SAT5a%yn


// Paypal Sandbox (Thai)
// sb-r47qoc23835767@personal.example.com
// Lfx3QV./

*/