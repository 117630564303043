import { Component } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import { environment } from 'src/environments/environment';

// declare ga as a function to access the JS code in TS
declare let ga: Function;
@Component({
    selector: 'app',
    styleUrls: ['./app.component.css'],  
    templateUrl: 'app.component.html'
})

export class AppComponent { 
    constructor(private translate: TranslateService,
        private router: Router) {

        translate.setDefaultLang('en');
        
        // if(environment.googleAnalyticsEnabled) {
        //     this.router.events.subscribe(event => {
        //         if (event instanceof NavigationEnd) {
        //             ga('set', 'page', event.urlAfterRedirects);
        //             ga('send', 'pageview');
        //         }
        //     });
        // }
    }

    isLoggedIn() {        
        return (localStorage.getItem('currentUser') != null);        
    }
}