import { NgModule  } from '@angular/core'; 
import { TranslateModule } from '@ngx-translate/core';
import { YesNoDialogComponent } from './yes-no/yes-no-dialog';

@NgModule({
  imports: [
    TranslateModule,
  ],
  declarations: [
    YesNoDialogComponent
  ],
  exports: [
    YesNoDialogComponent
  ]
})

export class SharedDialog { }