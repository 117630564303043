
import { EventEmitter, Injectable, NgZone } from "@angular/core";
import { environment } from "src/environments/environment";
import { UserSession } from "../_models";
import { Observable } from "rxjs";
import { Subject } from 'rxjs';
 
@Injectable()
export class SSEService {
    private eventSource: EventSource;
    //public message = new Subject<string>();    
    constructor(private zone: NgZone) {}

    observeMessages(): Observable<string> {
        return new Observable<string>(observer => {
            this.eventSource = this.getEventSource();
            
            this.eventSource.onopen = (ev) => {
              if(!environment.production) console.log('Connection to server opened.', ev);
            };
            
            this.eventSource.onerror = (ev) => {
              if(!environment.production) console.log('EventSource failed.', ev);
            };

            this.eventSource.addEventListener('message',(evt : any) => {
              if(!environment.production) console.log("sse", String(evt.data));

              this.zone.run(() => {
                observer.next(evt.data);
              });
            });
          });
    }
    
    private getEventSource(): EventSource {
        let userSession = JSON.parse(localStorage.getItem('currentUser'));
        let url: string = `${environment.sseUrl}/stream?accessToken=${userSession.accessToken}`;
        if (this.eventSource) {
            return this.eventSource;
        } else {
            this.eventSource = new EventSource(url);
            return this.eventSource;
        }        
    }
}

// https://github.com/aicsgithub/aics-file-upload-app/blob/bc05ff8ee7cc88a14b961f562ec3142c8b8f3b58/src/renderer/containers/App/AutoReconnectingEventSource.ts


// import { EventEmitter, Injectable, NgZone } from "@angular/core";
// import { environment } from "src/environments/environment";
// import { UserSession } from "../_models";
// import { Observable } from "rxjs";
 

// @Injectable()
// export class SSEService {
//     public sseEmitter: EventEmitter<any>;
//     userSession: UserSession;
    
//     constructor(private zone: NgZone) {
//         this.sseEmitter = new EventEmitter();        
//     }

//     observeMessages(): Observable<string> {
//         this.userSession = JSON.parse(localStorage.getItem('currentUser'));
//         const sseEndpointUrl = `${environment.sseUrl}/stream?accessToken=${this.userSession.accessToken}`;
         
//         return new Observable<string>(obs => {
//             const es = new EventSource(sseEndpointUrl);
//             es.addEventListener('message', (evt : any) => {
//                 if(!environment.production) console.log("sse", evt.data);
//                 this.zone.run(() => obs.next(evt.data));
//             }); 
//             return () => es.close();
//         });
//     } 
// }

//