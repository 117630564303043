import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { GetDashboard } from '../_models/get.dashboard';
import { environment } from 'src/environments/environment';
import { UserSession } from '../_models';
import { GetDeviceResp } from "../_models/get.device.response"
import { GetDevicesResp } from "../_models/get.devices.response"
import { getProductsResp } from '../_models/get.products.response';
import { getRoomsResp } from '../_models/get.rooms.response';
import { CreateOrUpdateDeviceResp } from '../_models/create.update.devices.response';
import { DeleteResp } from '../_models/delete.response';
import * as uuid from 'uuid';
import { HmacSHA256 } from "crypto-js";
import * as CryptoJS from 'crypto-js';
import { GetDeviceActionResp } from '../_models/get.device.action';
import { AccountResp  } from '../_models/account.response';
import { BaseResp } from '../_models/base.response';
import { getHomesResp } from '../_models/get.homes.response';
import { getMySelfResp } from '../_models/get.myself.response';
import { getAPICredentialsResp } from '../_models/get.api.credential.response';
import { getAppKeyAndSecretCredentialResp } from '../_models/get.appkey.and.secret.response';
import { GetActivityLogResp } from '../_models/get.activitylog.response';
import { GetPowerUsageResp } from '../_models/get.powerusages.response';
import { GetIftttApiKeyResp } from '../_models/get.iftttapikey.response';
import { getSchedulesResp } from '../_models/get.schedules.response';
import { Subscription } from '../_models/subscription';
import { getSubscriptionResp } from '../_models/get.subscription.response';
import { GetSubscriptionOrderTotal } from '../_models/get.subscription.order.total';
import { GetTodayAvgAirQualityResp } from '../_models/get.today.avg.air.quality.response';
import { getSceneResp } from '../_models/get.scene.response';
import { CreateOrUpdateSceneResp } from '../_models/create.update.scene.response';
import { GetCapabilitiesResp } from '../_models/get.capabilities.response';
import { GetDeviceTypesResp } from '../_models/get.device.types.response';
import { getDeviceTemplatesResp } from '../_models/get.device.templates.response';
import { getDeviceTemplateResp } from '../_models/get.device.template.response';
import { GetUnitOfMeasure } from '../_models/get.unit.of.measure.response';
import { getGenCodeResp } from '../_models/get.gen.code.response';
import { GetDeviceUsageEstimationsResp } from '../_models/get.device.usage.estimations.response';
import { getExampleCodeResp } from '../_models/get.example.code.response';
import { SubscriptionResp } from '../_models/subscription.response';
import { GetSubscriptionPaymentStatus } from '../_models/get.subscription.payment.response';
import { getCompileResp } from '../_models/get.compile.response';
import { GetDeviceContactHistoryResp } from '../_models/get.device.contact.history.respose';
import { getAllSubscriptionsResp } from '../_models/get.all.subscriptions.response';
import { GetExchangeRateResp } from '../_models/get.exchange.rate';
import { GetCreateStripeCheckoutSession } from '../_models/get.subscription.order.total copy';
import { GetEmailIsVerifiedRes } from '../_models/get.email.is.verified.response';

// import { Dashboard } from '../_models';

@Injectable()
export class APIService {
     
    constructor(private http: HttpClient) { }
         
    getDashboard() {
        const endpointUrl = `${environment.apiUrl}/api/v1/dashboard`;
        return this.http.get<GetDashboard>(endpointUrl);
    }
    
    getDevices() {
        const endpointUrl = `${environment.apiUrl}/api/v1/devices`;
        return this.http.get<GetDevicesResp>(endpointUrl);
    }

    getDevice(deviceId: any) {
        const endpointUrl = `${environment.apiUrl}/api/v1/devices/${deviceId}`;
        return this.http.get<GetDeviceResp>(endpointUrl);
    }

    getDeviceTemperature(deviceId: any, from: any, to: any) {
        const queryParameters = {
            "from": from,
            "to": to,
        };

        const headers = new HttpHeaders({});
        return this.http.get<GetDeviceActionResp>(`${environment.apiUrl}/api/v1/devices/${deviceId}/temperature`, { params: queryParameters, headers: headers } );
    }

    getDeviceAirQuality(deviceId: any, from: any, to: any) {
        const queryParameters = {
            "from": from,
            "to": to,
        };

        const headers = new HttpHeaders({});
        return this.http.get<GetDeviceActionResp>(`${environment.apiUrl}/api/v1/devices/${deviceId}/airquality`, { params: queryParameters, headers: headers } );
    }

    getDevicePowerUsage(deviceId: any, from: any, to: any) {
        const queryParameters = {
            "from": from,
            "to": to,
        };

        const headers = new HttpHeaders({});
        return this.http.get<GetPowerUsageResp>(`${environment.apiUrl}/api/v1/devices/${deviceId}/powerusage`, { params: queryParameters, headers: headers } );
    }
 
    getDeviceContactEvents(deviceId: any, from: any, to: any) {
        const queryParameters = {
            "from": from,
            "to": to,
        };

        const headers = new HttpHeaders({});
        return this.http.get<GetDeviceContactHistoryResp>(`${environment.apiUrl}/api/v1/devices/${deviceId}/events/contact`, {  params: queryParameters, headers: headers } );
    }

    createDevice(deviceName: any, description: any, productId: any, roomId: any, accessKeyId: any, notifications: any, timers: any, 
            energyUsageEstimation: any, unlockPin: any, cameraStreamConfiguration: any) {
        const endpointUrl = `${environment.apiUrl}/api/v1/devices`;
        
        let postData = {name: deviceName, description: description, productId: productId, roomId: roomId, 
                        accessKeyId: accessKeyId, notifications: notifications, timers: timers, 
                        energyUsageEstimation: energyUsageEstimation,
                        unlockPin: unlockPin,
                        cameraStreamConfiguration: cameraStreamConfiguration};
        return this.http.post<CreateOrUpdateDeviceResp>(endpointUrl, postData);
    }
 
    updateDevice(deviceId: any, deviceName: any, description: any, productId: any, roomId: any, accessKeyId: any, notifications: any, timers: any, 
            energyUsageEstimation: any, unlockPin: any, cameraStreamConfiguration: any) {
        const endpointUrl = `${environment.apiUrl}/api/v1/devices`;

        let putData = {id: deviceId, name: deviceName, description: description, productId: productId, roomId: roomId, 
                        accessKeyId: accessKeyId, notifications: notifications, timers: timers, 
                        energyUsageEstimation: energyUsageEstimation, 
                        unlockPin: unlockPin,
                        cameraStreamConfiguration: cameraStreamConfiguration};
        return this.http.put<CreateOrUpdateDeviceResp>(endpointUrl, putData);
    }

    deleteDevice(deviceId: string) {
        const endpointUrl = `${environment.apiUrl}/api/v1/devices/${deviceId}`;
        return this.http.delete<DeleteResp>(endpointUrl);
    }

    createApiKey(apiKeyName: any) {
        const endpointUrl = `${environment.apiUrl}/api/v1/credentials/api`;
        let postData = {name: apiKeyName};
        return this.http.post<CreateOrUpdateDeviceResp>(endpointUrl, postData);
    }

    getApiCredentials() {
        const endpointUrl = `${environment.apiUrl}/api/v1/credentials/api`;
        return this.http.get<getAPICredentialsResp>(endpointUrl);
    }

    getGenCode(deviceTemplateId, deviceId) {
        const endpointUrl = `${environment.apiUrl}/api/v1/codegen/gen`;
        const queryParameters = {
            "deviceTemplateId": deviceTemplateId,
            "download": "false",
            "deviceId" : deviceId
        };

        const headers = new HttpHeaders({});
        return this.http.get<getGenCodeResp>(endpointUrl, { params: queryParameters, headers: headers } );        
    }

    getCodeExample(exampleUrl) {
        const endpointUrl = `${environment.apiUrl}/api/v1/codegen/codeexample`;
        const queryParameters = {
            "exampleUrl": exampleUrl
        };

        const headers = new HttpHeaders({});
        return this.http.get<getExampleCodeResp>(endpointUrl, { params: queryParameters, headers: headers } );        
    }

    

    downloadCode(deviceTemplateId, deviceId) {
        const endpointUrl = `${environment.apiUrl}/api/v1/codegen/gen`;
        const queryParameters = {
            "deviceTemplateId": deviceTemplateId,
            "deviceId" : deviceId,
            "download": "true"
        };

        let headers = new HttpHeaders({});
        return this.http.get(endpointUrl, { params: queryParameters, headers: headers, responseType: "blob" } );   
    }

    downloadZeroCode(selectedProductCode, accessory, gpioMappingsArr, enableSDKLogs, restoreState, wiFiName, wiFiPassword) {
        const endpointUrl = `${environment.apiUrl}/api/v1/lowcode/gen`;
        const queryParameters = {
            "selectedProductCode": selectedProductCode,
            "accessory": JSON.stringify(accessory),
            "gpioMappings" : JSON.stringify(gpioMappingsArr),
            "enableSDKLogs": enableSDKLogs,
            "restoreState" : restoreState,
            "wiFiName" : wiFiName,
            "wiFiPassword" : wiFiPassword
        };

        let headers = new HttpHeaders({});
        return this.http.get(endpointUrl, { params: queryParameters, headers: headers, responseType: "blob" } );   
    }

    compile(fqbn, selectedProductCode, accessory, gpioMappingsArr, enableSDKLogs, restoreState, wiFiName, wiFiPassword) {
        const endpointUrl = `${environment.apiUrl}/api/v1/lowcode/compile`;
        const queryParameters = {
            "selectedProductCode": selectedProductCode,
            "accessory": JSON.stringify(accessory),
            "gpioMappings" : JSON.stringify(gpioMappingsArr),
            "enableSDKLogs": enableSDKLogs,
            "restoreState" : restoreState,
            "wiFiName" : wiFiName,
            "wiFiPassword" : wiFiPassword,
            "fqbn" : fqbn
        };

        let headers = new HttpHeaders({});
        return this.http.get<getCompileResp>(endpointUrl, { params: queryParameters, headers: headers } );   
    }
    
    deleteApiCredentials(keyId) {
        const endpointUrl = `${environment.apiUrl}/api/v1/credentials/api/${keyId}`;
        return this.http.delete<DeleteResp>(endpointUrl);
    }

    createAppKeyAndSecret(apiKeyName: any) {
        const endpointUrl = `${environment.apiUrl}/api/v1/credentials/appkeyandsecret`;
        let postData = {name: apiKeyName};
        return this.http.post<CreateOrUpdateDeviceResp>(endpointUrl, postData);
    }
    
    getAppKeyAndSecret() {
        const endpointUrl = `${environment.apiUrl}/api/v1/credentials/appkeyandsecret`;
        return this.http.get<getAppKeyAndSecretCredentialResp>(endpointUrl);
    }

    deleteAppKeyAndSecret(keyId) {
        const endpointUrl = `${environment.apiUrl}/api/v1/credentials/appkeyandsecret/${keyId}`;
        return this.http.delete<DeleteResp>(endpointUrl);
    }
   
    getProducts() {
        const endpointUrl = `${environment.apiUrl}/api/v1/products`;
        return this.http.get<getProductsResp>(endpointUrl);
    }

    getRooms() {
        const endpointUrl = `${environment.apiUrl}/api/v1/rooms`;
        return this.http.get<getRoomsResp>(endpointUrl); // , { params: { includeRoomDevices: includeRoomDevices } }
    }
  
    getDeviceUsageEstimations(from: any, to: any) {
        const queryParameters = {
            "from": from,
            "to": to,
        };

        const headers = new HttpHeaders({});
        return this.http.get<GetDeviceUsageEstimationsResp>(`${environment.apiUrl}/api/v1/deviceusageestimations`, { params: queryParameters, headers: headers } );
    }

    deleteRoom(roomId: string) {
        const endpointUrl = `${environment.apiUrl}/api/v1/rooms/${roomId}`;
        return this.http.delete<DeleteResp>(endpointUrl);
    }

    getHomes() {
        const endpointUrl = `${environment.apiUrl}/api/v1/homes`;
        return this.http.get<getHomesResp>(endpointUrl);
    }

    getSchedules() {
        const endpointUrl = `${environment.apiUrl}/api/v1/schedules`;
        return this.http.get<getSchedulesResp>(endpointUrl);
    }

    createSchedule(enabled: any, scheduleType: any, name: any, description: any, deviceId: any, action: any, actionValue: any, hour: any, minute: any,  weekdays: any) {
        const endpointUrl = `${environment.apiUrl}/api/v1/schedules`;
        
        const postData = { 
            enable: enabled, scheduleType: scheduleType, name: name, description: description, deviceId: deviceId, action: action, 
            actionValue: actionValue, hour: hour, minute: minute,
            weekdays: weekdays 
        };

        return this.http.post<CreateOrUpdateDeviceResp>(endpointUrl, postData);
    }

    updateSchedule(id: any, scheduleType: any, enabled: any, name: any, description: any, deviceId: any, action: any, actionValue: any, hour: any, minute: any, weekdays: any) {
        const endpointUrl = `${environment.apiUrl}/api/v1/schedules`;

        let putData = { 
            id: id, scheduleType: scheduleType, enable: enabled, name: name, description: description, deviceId: deviceId, action: action, 
            actionValue: actionValue, hour: hour, minute: minute,
            weekdays: weekdays 
        };

        return this.http.put<CreateOrUpdateDeviceResp>(endpointUrl, putData);
    }
     

    deleteSchedule(scheduleId: string) {
        const endpointUrl = `${environment.apiUrl}/api/v1/schedules/${scheduleId}`;
        return this.http.delete<DeleteResp>(endpointUrl);
    }

    createScene(name: any, description: any, deviceActions: any) {
        const endpointUrl = `${environment.apiUrl}/api/v1/scenes`;
        const postData = {  name: name, description: description, deviceActions: deviceActions };
        return this.http.post<CreateOrUpdateSceneResp>(endpointUrl, postData);
    }

    deleteScene(sceneId: string) {
        const endpointUrl = `${environment.apiUrl}/api/v1/scenes/${sceneId}`;
        return this.http.delete<DeleteResp>(endpointUrl);
    }

    testScene(sceneId: string) {
        const endpointUrl = `${environment.apiUrl}/api/v1/scenes/test/${sceneId}`;
        const postData = {  sceneId: sceneId };
        return this.http.post<BaseResp>(endpointUrl, postData);
    }
    

    getScenes() {
        const endpointUrl = `${environment.apiUrl}/api/v1/scenes`;
        return this.http.get<getSceneResp>(endpointUrl);
    }

    getActivityLog(deviceId?: string) {
        let endpointUrl;

        if(deviceId) {
            endpointUrl = `${environment.apiUrl}/api/v1/activitylog/device/${deviceId}`;
        } else {
            endpointUrl = `${environment.apiUrl}/api/v1/activitylog`;
        }
        
        return this.http.get<GetActivityLogResp>(endpointUrl);
    }

    getIftttApiKey() {
        let endpointUrl = `${environment.apiUrl}/api/v1/apiintegrations/ifttt/`;
        return this.http.get<GetIftttApiKeyResp>(endpointUrl);
    }

    updateIftttApiKey(apikey:any, platform:any) {
        const endpointUrl = `${environment.apiUrl}/api/v1/apiintegrations/ifttt/`;
        let putData = {apikey: apikey, platform: platform };
        return this.http.put<BaseResp>(endpointUrl, putData);
    }

    getSecondsSinceEpoch(){ return Math.floor( Date.now() / 1000 ) }

    getSignature(queryParameters, apikey) {
        const queryString = Object.keys(queryParameters).map(key => key + '=' + queryParameters[key]).join('&');
        const hash = HmacSHA256(queryString, apikey);
        return hash.toString(CryptoJS.enc.Base64);
    }

    getActionCommand(action, messageId, value, instanceId?: string) {
        let command = {
            clientId: environment.appClientId,
            messageId: messageId.toString(),
            type: "request",
            instanceId: instanceId,
            action: action,
            createdAt: this.getSecondsSinceEpoch() as any,
            value: value
        };

        if(!instanceId) {
            delete command.instanceId;
        }

        return command
    }

    doorbell(deviceId: any) {
        const messageId = uuid.v4();        
          
        const queryParameters = {
            "clientId": environment.appClientId,
            "messageId": messageId.toString(),
            "type" : "event",
            "action": "DoorbellPress",
            "createdAt": this.getSecondsSinceEpoch().toString(),
            "value": JSON.stringify({ "state": "pressed"})
        };

        const headers = new HttpHeaders({});
        return this.http.get<GetDeviceActionResp>(`${environment.apiUrl}/api/v1/devices/${deviceId}/action`, { params: queryParameters, headers: headers } );
    }

    turnOnOrOffDevice(deviceId: any,  powerState: any) {
        const value = JSON.stringify({  "state": powerState });
        const messageId = uuid.v4();      
        const queryParameters = this.getActionCommand("setPowerState", messageId, value);  
              
        return this.http.get<GetDeviceActionResp>(`${environment.apiUrl}/api/v1/devices/${deviceId}/action`, { params: queryParameters });
    }    

    setToggleState(deviceId: any,  powerState: any, instanceId: any) {
        const value = JSON.stringify({  "state": powerState });
        const messageId = uuid.v4();      
        const queryParameters = this.getActionCommand("setToggleState", messageId, value, instanceId);  
              
        return this.http.get<GetDeviceActionResp>(`${environment.apiUrl}/api/v1/devices/${deviceId}/action`, { params: queryParameters });
    }
    
    mediaControl(deviceId: any,  control: any) {
        const messageId = uuid.v4();  
        const value =  JSON.stringify({ "control": control });
        const queryParameters = this.getActionCommand("mediaControl", messageId, value);    
        return this.http.get<GetDeviceActionResp>(`${environment.apiUrl}/api/v1/devices/${deviceId}/action`, { params: queryParameters} );
    }

    setVolume(deviceId: any,  volume: any) {
        const messageId = uuid.v4();  
        const value = JSON.stringify({ "volume": Number(volume) });
        const queryParameters = this.getActionCommand("setVolume", messageId, value); 
        return this.http.get<GetDeviceActionResp>(`${environment.apiUrl}/api/v1/devices/${deviceId}/action`, { params: queryParameters} );
    }

    setChannelUpOrDown(deviceId: any,  isUp: boolean) {
        const messageId = uuid.v4();  
        const value = JSON.stringify({ "channelCount": isUp ? 1 : -1 });
        const queryParameters = this.getActionCommand("skipChannels", messageId, value); 
        return this.http.get<GetDeviceActionResp>(`${environment.apiUrl}/api/v1/devices/${deviceId}/action`, { params: queryParameters} );
    }

    setMute(deviceId: any,  mute: any) {
        const messageId = uuid.v4();  
        const value = JSON.stringify({ "mute": mute });
        const queryParameters = this.getActionCommand("setMute", messageId, value); 
        return this.http.get<GetDeviceActionResp>(`${environment.apiUrl}/api/v1/devices/${deviceId}/action`, { params: queryParameters } );
    }
 
    setMode(deviceId: string, mode: string, instanceId?: string) {
        const messageId = uuid.v4();  
        const value = JSON.stringify({ "mode": mode });
        const queryParameters = this.getActionCommand("setMode", messageId, value, instanceId); 
        return this.http.get<GetDeviceActionResp>(`${environment.apiUrl}/api/v1/devices/${deviceId}/action`, { params: queryParameters } );
    }
    
    
    setThermostatMode(deviceId: any,  thermostatMode: any) {
        const messageId = uuid.v4();  
        const value = JSON.stringify({ "thermostatMode": thermostatMode });
        const queryParameters = this.getActionCommand("setThermostatMode", messageId, value);
        return this.http.get<GetDeviceActionResp>(`${environment.apiUrl}/api/v1/devices/${deviceId}/action`, { params: queryParameters } );
    }

    targetTemperature(deviceId: any,  temperature: any) {
        const messageId = uuid.v4();  
        const value = JSON.stringify({ "temperature": Number(temperature) });
        const queryParameters = this.getActionCommand("targetTemperature", messageId, value);
        return this.http.get<GetDeviceActionResp>(`${environment.apiUrl}/api/v1/devices/${deviceId}/action`, { params: queryParameters } );
    }
     
    setLockState(deviceId: any, state: any) {
        const messageId = uuid.v4();  
        const value = JSON.stringify({ "state": state });
        const queryParameters = this.getActionCommand("setLockState", messageId, value);
        return this.http.get<GetDeviceActionResp>(`${environment.apiUrl}/api/v1/devices/${deviceId}/action`, { params: queryParameters} );
    }

    changeColor(deviceId: any,  r: any, g: any, b: any) {
        const messageId = uuid.v4();  
        const value = JSON.stringify({
            "color": {
              "r": r,
              "g": g,
              "b": b
            }
        });

        const queryParameters = this.getActionCommand("setColor", messageId, value);
        return this.http.get<GetDeviceActionResp>(`${environment.apiUrl}/api/v1/devices/${deviceId}/action`, { params: queryParameters} );
    }

    motionEvent(deviceId: any,  state: any, apikey: any, messageId: any) {
        const queryParameters = {
            "clientId": environment.appClientId,
            "messageId": messageId.toString(),
            "type" : "event",
            "action": "motion",
            "createdAt": this.getSecondsSinceEpoch().toString(),
            "value": JSON.stringify({
                "state": state
            })
        };
     
        return this.http.get<GetDeviceActionResp>(`${environment.apiUrl}/api/v1/devices/${deviceId}/action`, { params: queryParameters} );
    }
    
    contactEvent(deviceId: any,  state: any, apikey: any, messageId: any) {
        const queryParameters = {
            "clientId": environment.appClientId,
            "messageId": messageId.toString(),
            "type" : "event",
            "action": "setContactState",
            "createdAt": this.getSecondsSinceEpoch().toString(),
            "value": JSON.stringify({
                "state": state
            })
        };
    
        return this.http.get<GetDeviceActionResp>(`${environment.apiUrl}/api/v1/devices/${deviceId}/action`, { params: queryParameters } );
    }

    setBrightness(deviceId: any,  brightness: any) {
        const messageId = uuid.v4();  
        const value = JSON.stringify({ "brightness": Number(brightness) });
        const queryParameters = this.getActionCommand("setBrightness", messageId, value);
        return this.http.get<GetDeviceActionResp>(`${environment.apiUrl}/api/v1/devices/${deviceId}/action`, { params: queryParameters} );
    }

    setSetting(deviceId, settingId, settingValue) {
        const messageId = uuid.v4();  
        const value = JSON.stringify({ "id": settingId, "value": settingValue});
        const queryParameters = this.getActionCommand("setSetting", messageId, value);
        return this.http.get<GetDeviceActionResp>(`${environment.apiUrl}/api/v1/devices/${deviceId}/action`, { params: queryParameters} );
    }
    
    setPowerLevel(deviceId: any,  level: any) {
        const messageId = uuid.v4();  
        const value = JSON.stringify({ "powerLevel": Number(level) });
        const queryParameters = this.getActionCommand("setPowerLevel", messageId, value);
        return this.http.get<GetDeviceActionResp>(`${environment.apiUrl}/api/v1/devices/${deviceId}/action`, { params: queryParameters} );
    }

    setPercentage(deviceId: any,  percentage: any) {
        const messageId = uuid.v4();  
        const value = JSON.stringify({ "percentage": Number(percentage) });
        const queryParameters = this.getActionCommand("setPercentage", messageId, value);
        return this.http.get<GetDeviceActionResp>(`${environment.apiUrl}/api/v1/devices/${deviceId}/action`, { params: queryParameters} );
    }

    setEqualizer(deviceId: any, bands: any) {
        const messageId = uuid.v4();  
        const value = JSON.stringify({ "bands": bands });
        const queryParameters = this.getActionCommand("setBands", messageId, value);
        return this.http.get<GetDeviceActionResp>(`${environment.apiUrl}/api/v1/devices/${deviceId}/action`, { params: queryParameters} );
    }
    

    setRangeValue(deviceId: any,  level: any, instanceId?: string) {
        const messageId = uuid.v4();  
        const value = JSON.stringify({ "rangeValue": Number(level)});
        const queryParameters = this.getActionCommand("setRangeValue", messageId, value, instanceId);
        return this.http.get<GetDeviceActionResp>(`${environment.apiUrl}/api/v1/devices/${deviceId}/action`, { params: queryParameters} );
    }

    register(fullName: any, email: any, password: any, timeZone: any, temperatureScale: any, manufacturerId: any, language: any, recaptcha: any, clientId: any) {
        const endpointUrl = `${environment.apiUrl}/api/v1/users/register`;
        let postData = {name: fullName, email: email, password: password, temperatureScale: temperatureScale, timeZone: timeZone, 
            manufacturerId: manufacturerId, language: language, recaptcha: recaptcha, clientId: clientId};
        return this.http.post<AccountResp>(endpointUrl, postData);
    }

    updateAccount(fullName: any, email: any, timeZone: any, temperatureScale: any, language: any) {
        const endpointUrl = `${environment.apiUrl}/api/v1/users/myself`;
        let postData = {name: fullName, email: email, temperatureScale: temperatureScale, timeZone: timeZone, language: language};
        return this.http.patch<AccountResp>(endpointUrl, postData);
    }

    activateAccount(verificationToken: any) {
        const endpointUrl = `${environment.apiUrl}/api/v1/users/activation/${verificationToken}`;
        return this.http.get<BaseResp>(endpointUrl);
    }

    forgotPassword(email: any) {
        const postData = {email: email };
        const endpointUrl = `${environment.apiUrl}/api/v1/users/forgotpassword`;
        return this.http.post<BaseResp>(endpointUrl, postData);
    }

    resetPassword(password: any, verificationToken: any) {
        const postData = {password: password, verificationToken: verificationToken };
        const endpointUrl = `${environment.apiUrl}/api/v1/users/resetpassword`;
        return this.http.post<BaseResp>(endpointUrl, postData);
    }

    changePassword(oldPassword: any, newPassword: any) {
        const postData = {oldPassword: oldPassword,  newPassword: newPassword};
        const endpointUrl = `${environment.apiUrl}/api/v1/users/myself/password`;
        return this.http.put<BaseResp>(endpointUrl, postData);
    }

    logout() {
        const endpointUrl = `${environment.apiUrl}/api/v1/auth/logout`;
        return this.http.post<BaseResp>(endpointUrl, {});
    }

    resendAccountConfirmationEmail(email: string) {
        const postData = {email: email };
        const endpointUrl = `${environment.apiUrl}/api/v1/users/resendaccountconfirmationemail`;
        return this.http.post<BaseResp>(endpointUrl, postData);
    }

    isMyEmailConfirmed(email: string) {
        const postData = {email: email };
        const endpointUrl = `${environment.apiUrl}/api/v1/users/myself/isemailconfirmed`;
        return this.http.post<GetEmailIsVerifiedRes>(endpointUrl, postData);
    }

    createSubscription(noOfDevices, noOfYears, orderTotal, discountCode, paymentProvider, paypalResp, omiseResp, currency) {
        const endpointUrl = `${environment.apiUrl}/api/v1/subscriptions`;
        const postData = { noOfDevices: noOfDevices, 
            noOfYears: noOfYears, 
            orderTotal: orderTotal, 
            discountCode:discountCode, 
            paymentProvider: paymentProvider, 
            paypalResp: paypalResp, 
            omiseResp: omiseResp,
            currency: currency 
        };
        return this.http.post<SubscriptionResp>(endpointUrl, postData);
    }

    updateSubscription(subscriptionId, noOfDevices, noOfYears, orderTotal, paymentProvider, currency) {
        const endpointUrl = `${environment.apiUrl}/api/v1/subscriptions`;
        let postData = {id: subscriptionId, noOfDevices: noOfDevices, noOfYears: noOfYears, orderTotal: orderTotal, 
            paymentProvider: paymentProvider,
            currency: currency 
        };
        
        return this.http.put<SubscriptionResp>(endpointUrl, postData);
    }

    getSubscriptionsPaymentStatus(sessionId) {
        const endpointUrl = `${environment.apiUrl}/api/v1/subscriptions/stripe/${sessionId}`;
        return this.http.get<GetSubscriptionPaymentStatus>(endpointUrl);
    }

    createRoom(roomName: any, description: any, homeId: any) {
        const endpointUrl = `${environment.apiUrl}/api/v1/rooms`;
        let postData = {name: roomName, description: description, homeId: homeId};
        return this.http.post<BaseResp>(endpointUrl, postData);
    }

    updateRoom(roomId: any, roomName: any, description: any, homeId: any) {
        const endpointUrl = `${environment.apiUrl}/api/v1/rooms`;
        let postData = {id: roomId, name: roomName, description: description, homeId: homeId};
        return this.http.put<BaseResp>(endpointUrl, postData);
    }
     
    getMySelf() {
        const endpointUrl = `${environment.apiUrl}/api/v1/users/myself`;
        return this.http.get<getMySelfResp>(endpointUrl);
    }

    deleteMySelf(reason) {
        const endpointUrl = `${environment.apiUrl}/api/v1/users/myself`;
        let httpParams = new HttpParams().set('reason', reason);
        let options = { params: httpParams };
        return this.http.delete<getMySelfResp>(endpointUrl, options);
    }

    getSubscription(subscriptionId: any) {
        const endpointUrl = `${environment.apiUrl}/api/v1/subscriptions/${subscriptionId}`;
        return this.http.get<getSubscriptionResp>(endpointUrl);
    }

    getAllSubscriptions() {
        const endpointUrl = `${environment.apiUrl}/api/v1/subscriptions`;
        return this.http.get<getAllSubscriptionsResp>(endpointUrl);
    }
    
    reassignSubscription(deviceIds, toSubscriptionId) {
        let postData = {
            deviceIds: deviceIds,
            toSubscriptionId: toSubscriptionId
        };

        const endpointUrl = `${environment.apiUrl}/api/v1/subscriptions/reassign`;
        return this.http.post<BaseResp>(endpointUrl, postData);
    }

    getExchangeRate() {
        const endpointUrl = `${environment.apiUrl}/api/v1/subscriptions/exchangerate`;
        return this.http.get<GetExchangeRateResp>(endpointUrl);
    }

    calculateSubscriptionsTotal(noOfDevices:number, noOfYears:number, discountCode:string) {
        let postData = {
            noOfDevices: String(noOfDevices),
            noOfYears: String(noOfYears),
            discountCode: String(discountCode)
        };

        const endpointUrl = `${environment.apiUrl}/api/v1/subscriptions/calculateSubscriptionsTotal`;
        return this.http.post<GetSubscriptionOrderTotal>(endpointUrl, postData);
    }

    createStripeCheckoutSession(noOfDevices, noOfYears, discountCode, orderTotal, currency, mode, isNewSubscription, subscriptionId) {
        let postData = {
            noOfDevices: String(noOfDevices),
            discountCode: String(discountCode),
            orderTotal: String(orderTotal),
            currency: String(currency),
            noOfYears: String(noOfYears),
            mode: mode,
            isNewSubscription: isNewSubscription,
            subscriptionId: subscriptionId
        };

        const endpointUrl = `${environment.apiUrl}/api/v1/subscriptions/stripe/createCheckoutSession`;
        return this.http.post<GetCreateStripeCheckoutSession>(endpointUrl, postData);
    }

    getTodayAvgAirQuality(deviceId: any) {
        const endpointUrl = `${environment.apiUrl}/api/v1/devices/${deviceId}/airquality/todayAvg`;
        return this.http.get<GetTodayAvgAirQualityResp>(endpointUrl);
    }

    verifyRecaptcha(recaptcha: any) {
        const endpointUrl = `${environment.apiUrl}/api/v1/users/verifyrecaptcha`;
        let postData = {recaptcha: recaptcha };
        return this.http.post<BaseResp>(endpointUrl, postData);
    }
    
    getCapabilities() {
        const endpointUrl = `${environment.apiUrl}/api/v1/devicetemplate/capabilities`;
        return this.http.get<GetCapabilitiesResp>(endpointUrl);
    }

    getDeviceTypes() {
        const endpointUrl = `${environment.apiUrl}/api/v1/devicetemplate/deviceTypes`;
        return this.http.get<GetDeviceTypesResp>(endpointUrl);
    }

    getUnitOfMeasure() {
        const endpointUrl = `${environment.apiUrl}/api/v1/devicetemplate/unitofmeasure`;
        return this.http.get<GetUnitOfMeasure>(endpointUrl);
    }
 
    createDeviceTemplate(name: any, description: any, deviceType: any, capabilities: any) {
        const endpointUrl = `${environment.apiUrl}/api/v1/devicetemplate`;
        let postData = {name: name, description: description, deviceType: deviceType, capabilities: capabilities};
        return this.http.post<BaseResp>(endpointUrl, postData);
    }

    updateDeviceTemplate(templateId: string, name: any, description: any, deviceType: any, capabilities: any) {
        const endpointUrl = `${environment.apiUrl}/api/v1/devicetemplate`;
        let postData = {id: templateId, name: name, description: description, deviceType: deviceType, capabilities: capabilities};
        return this.http.put<BaseResp>(endpointUrl, postData);
    }

    geDeviceTemplates() {
        const endpointUrl = `${environment.apiUrl}/api/v1/devicetemplate`;
        return this.http.get<getDeviceTemplatesResp>(endpointUrl);
    }

    deleteDeviceTemplates(deviceTemplateId: string) {
        const endpointUrl = `${environment.apiUrl}/api/v1/devicetemplate/${deviceTemplateId}`;
        return this.http.delete<DeleteResp>(endpointUrl);
    }

    getDeviceTemplateByProductId(productId: string) {
        const endpointUrl = `${environment.apiUrl}/api/v1/devicetemplate/byproduct/${productId}`;
        return this.http.get<getDeviceTemplateResp>(endpointUrl);
    }

    setChannel(deviceId: string, name: string) {
        const messageId = uuid.v4();  
        const value = JSON.stringify({"channel": { "name": name }});
        const queryParameters = this.getActionCommand("changeChannel", messageId, value); 
        return this.http.get<GetDeviceActionResp>(`${environment.apiUrl}/api/v1/devices/${deviceId}/action`, { params: queryParameters } );
    }

    setColorTemperature(deviceId: string, colorTemperature: string) {
        const messageId = uuid.v4();  
        const value = JSON.stringify({ "colorTemperature": Number(colorTemperature) });
        const queryParameters = this.getActionCommand("setColorTemperature", messageId, value); 
        return this.http.get<GetDeviceActionResp>(`${environment.apiUrl}/api/v1/devices/${deviceId}/action`, { params: queryParameters } );
    }

    sendKeyStroke(deviceId: string, keystroke: string) {
        const messageId = uuid.v4();  
        const value = JSON.stringify({ "keystroke": keystroke });
        const queryParameters = this.getActionCommand("sendKeystroke", messageId, value); 
        return this.http.get<GetDeviceActionResp>(`${environment.apiUrl}/api/v1/devices/${deviceId}/action`, { params: queryParameters } );
    }

    selectInput(deviceId: string, input: string) {
        const messageId = uuid.v4();  
        const value = JSON.stringify({ "input": input });
        const queryParameters = this.getActionCommand("selectInput", messageId, value); 
        return this.http.get<GetDeviceActionResp>(`${environment.apiUrl}/api/v1/devices/${deviceId}/action`, { params: queryParameters } );
    }

    deleteTemperatureUsage(deviceId: string) {
        const endpointUrl = `${environment.apiUrl}/api/v1/devices/${deviceId}/temperatureusage`;
        return this.http.delete<DeleteResp>(endpointUrl);
    }
    
    getWebRTCAnswer(deviceId: string, offer: string) {
        const endpointUrl = `${environment.apiUrl}/api/v1/devices/${deviceId}/action`;
        const offerInBase64 = btoa(offer);

        let postData = {
            clientId: environment.appClientId,
            messageId: uuid.v4().toString(),
            type: "request",
            action: 'getWebRTCAnswer',
            createdAt: this.getSecondsSinceEpoch() as any,
            value: JSON.stringify({ "offer": offerInBase64 })
        };
 
        return this.http.post<GetDeviceActionResp>(endpointUrl, postData); 
    }

    getCameraStreamUrl(deviceId: string, protocol: string) {
        const messageId = uuid.v4();  
        const value = JSON.stringify({
            protocol: protocol
        });
        const queryParameters = this.getActionCommand("getCameraStreamUrl", messageId, value); 
        return this.http.get<GetDeviceActionResp>(`${environment.apiUrl}/api/v1/devices/${deviceId}/action`, { params: queryParameters } );
    }

    
}